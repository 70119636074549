import BtnOutline from "../BtnOutline/BtnOutline"
import "./SectionHistoria.css"

function SectionHistoria() {
  return (
    <div className="bg">
      <div className="titleSectionHist">
        <h1>Uma empresa <strong>repleta de história</strong> </h1>
        <p>Desde 1983</p>
        <BtnOutline text={"Conheça a Cia Canoinhas"} link={"/historia"} />
      </div>
      <div className="img">
        <img src="../assets/fabril-canoinhas.jpeg" alt="" />
      </div>
    </div>
  )
}

export default SectionHistoria