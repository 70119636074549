import Cardproduto from "../../components/cardProduto/CardProduto";
import Footer from "../../components/Footer/Footer";
import HeroProduto from "../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../components/linhas/Linhas";
import Navbar from "../../components/Menu/Menu";
import NoticeSection from "../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../components/SocialMediaSection/SocialMediaSection";

function Fofinhofd() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Absorção"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Absorção"
    },
    {
      icon: icone,
      text: "Perfumado cheirinho de bebê"
    },
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Absorção"
    },
    {
      icon: icone,
      text: "Perfumado Chá Verde"
    },
  ]

  let infos4 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Macio"
    },
    {
      icon: icone,
      text: "Absorção"
    },

  ]

  const options9 = [
    {
      imagem: "../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_NEUTRO_4ROLOS.png",
      qtde: "4",
      un: "Rolos",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_8ROLOS.png",
      qtde: "8",
      un: "Rolos",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/FOLHA_DUPLA_12_ROLOS.png",
      qtde: "12",
      un: "Rolos",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/FOLHA_DUPLA_16_ROLOS.png",
      qtde: "16",
      un: "Rolos",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/PACKSHOT_FOLHA_DUPLA_30m_24ROLOS.png",
      qtde: "24",
      un: "Rolos",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/fofinho12compacto.png",
      qtde: "12 Rolos",
      un: "Compacto",
      cor: "#772835",
      visible: "#772835"
    },
    {
      imagem: "../../../../assets/Fofinho/32compacto.png",
      qtde: "32 Rolos",
      un: "Compacto",
      cor: "#772835",
      visible: "#772835"
    }
  ];

  const options10 = [
    {
      imagem: "../../../../assets/Fofinho/Fofinho Folha Dupla bebe - 1839.png",
      qtde: "4",
      un: "Rolos",
      cor: "#A31172",
      visible: "#A31172"
    }
  ];

  const options11 = [
    {
      imagem: "../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_CHÁ_VERDE_4ROLOS.png",
      qtde: "4",
      un: "Rolos",
      cor: "#7C964F",
      visible: "#7C964F"
    }
  ];

  const options3 = [
    {
      imagem: "../../../../assets/Natural/4.png",
      qtde: "4",
      un: "Rolos",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/fofinhoNatural8.png",
      qtde: "8",
      un: "Rolos",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/12normal.png",
      qtde: "12",
      un: "Rolos",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/16.png",
      qtde: "16",
      un: "Rolos",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/24.png",
      qtde: "24",
      un: "Rolos",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/12.png",
      qtde: "12 Rolos",
      un: "Compacto",
      cor: "#00748B",
      visible: "#00748B"
    },
    {
      imagem: "../../../../assets/Natural/32.png",
      qtde: "32 Rolos",
      un: "Compacto",
      cor: "#00748B",
      visible: "#00748B"
    }
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro"  style={{marginTop: "6rem"}}>
          <h1>Fofinho<br />
            <strong> e suas versões</strong></h1>
          <p>
            Com versões neutro, cheirinho de bebê e chá verde<br />
            os papéis higiênicos da linha fofinho atendem a todos os gostos<br />
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img  style={{marginTop: "6rem"}} src="../../../assets/duploHeader.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
     {/* Nona instância do componente HeroProduto */}
     <HeroProduto
        link={"../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_NEUTRO_4ROLOS.png"}
        cor={"#9f2e41"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#772835"}
        options={options9} 
        caracteristicas={infos}
      />

      {/* Décima instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Fofinho/Fofinho Folha Dupla bebe - 1839.png"}
        cor={"#bd3790"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#A31172"}
        options={options10} 
        caracteristicas={infos2}
      />

      {/* Décima primeira instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_CHÁ_VERDE_4ROLOS.png"}
        cor={"#acc87d"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#7C964F"}
        options={options11}
        caracteristicas={infos3}
      />

<HeroProduto
        link={"../../../../assets/Fofinho/FOFINHO_FOLHA_DUPLA_CHÁ_VERDE_4ROLOS.png"}
        cor={"#00AD99"}
        logo={"../../../assets/fofinhoNatural.png"}
        btnCor={"#00748B"}
        options={options3}
        caracteristicas={infos4}
      />
      
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />
  

    </>
  )
}

export default Fofinhofd;