import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function FofinhoAbsolutTriplo() {
  return (
    <>
      <Navbar />
      <HeroProduto
        link={"../../../../assets/fofinhoAbsPremium.png"}
        cor={"#5D689A"}
        logo={"../../../../assets/logoAbsPremium.png"}
        btnCor={"#002C72"} />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro">
          <h1>Suavidade<br />
            <strong> Absoluta</strong></h1>
          <p>
            Produzido com tecnologia 3D que cria camadas de ar<br /> entre as folhas, resultando numa textura ultra macia <br />com máxima absorção, tudo isso sem esfarelar. <br />Fabricado com fibras naturais.
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img src="../../../../assets/selopremium@3x.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
      <InfoSectionProduto
        cor={"#162E4F"}
        corIcon={"#5D689A"}
        urlImg={"../../../../assets/logoAbsPremium.png"}
        item1={"Testado e Aprovado"}
        item2={"Folha Tripla"}
        item3={"100% Celulose"}
      />
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <div className="sectionOutrosProdutos">
        <div className="outrosProdutos">
          <Cardproduto link={"/fofinhoAbsolut"} produto={"../../../../assets/FOFINHO-ABSOLUT.png"} logo={"../../../../assets/fofinhoAbs-logo.png"} cor={"#9B9B9D"} />
          <Cardproduto link={"/fofinhoAbsolutPremium"} produto={"../../../../assets/fofinhoAbsPremium.png"} logo={"../../../../assets/logoAbsPremium.png"} cor={"#5D699A"} />
          <Cardproduto link={"/fofinho"} produto={"../../../../assets/fofinho-neutro-4rolos.png"} logo={"../../../../assets/fofinhoLogoAzul.png"} cor={"#BAEBFF"} />
          <Cardproduto link={"/sorella"} produto={"../../../../assets/sorella.png"} logo={"../../../../assets/sorellaLogo.png"} cor={"#C26666"} />
          <Cardproduto link={"/best"} produto={"../../../../assets/BEST.png"} logo={"../../../../assets/bestLogo.png"} cor={"#DFCF89"} />
          <Cardproduto link={"/bambino"} produto={"../../../../assets/BAMBINO.png"} logo={"../../../../assets/BambinoLogo.png"} cor={"#BAEBFF"} />
        </div>
      </div>
      <SocialMediaSection />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      <NoticeSection />
      <Footer />

    </>
  )
}

export default FofinhoAbsolutTriplo;