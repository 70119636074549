import "./SocialMediaSection.css"

function SocialMediaSection() {
  return (
    <div className="SocialSection">
      <div className="tilteSectionSocialMedia">
        <h1>
          Siga nossas<br />
          <strong>Redes Sociais</strong>
        </h1>
        <p>
          Fique por dentro das novidades, e receba
          ótimos <br />conteúdos em seu feed
        </p>
        <div className="iconsSocial">
          <a style={{color: "#fff"}} href="https://pt-br.facebook.com/ciacanoinhas/"> <img src="https://img.icons8.com/color/68/null/facebook-new.png" alt="facebook" /> </a>
          <a style={{color: "#fff"}}href="https://www.instagram.com/ciacanoinhas/">  <img src="https://img.icons8.com/color/68/null/instagram-new--v1.png" alt="instagram" /></a>
          <a style={{color: "#fff"}} href="https://www.linkedin.com/company/ciacanoinhasdepapel/"> <img src="https://img.icons8.com/fluency/68/null/linkedin.png" alt="linkedIn" /></a>

        </div>
      </div>
      <div className="celularInstagram">
        <img id="cell" src="../../assets/celular.png" alt="Celular" />
      </div>
    </div>
  )
}

export default SocialMediaSection;