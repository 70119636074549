import Cardproduto from "../../components/cardProduto/CardProduto";
import Footer from "../../components/Footer/Footer";
import HeroProduto from "../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../components/linhas/Linhas";
import Navbar from "../../components/Menu/Menu";
import NoticeSection from "../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../components/SocialMediaSection/SocialMediaSection";
import "./Produto.css";

import React, { useState, useEffect } from 'react';

function Produto() {
  
  const [categoryDetails, setCategoryDetails] = useState({ products: [] });

  async function updateCategoryDetails () {
    const queryParams = new URLSearchParams(window.location.search);
    const reference = queryParams.get('ref');

    const response = await fetch('https://www.canoinhas.com.br/painel/api/categories/get-one?id=' + reference);
    const data = await response.json();

    setCategoryDetails(data);
  }

  useEffect(() => {
    updateCategoryDetails();
  }, []);

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro"  style={{marginTop: "6rem"}}>
          <h1>{categoryDetails.category_title}</h1>
          <p>{categoryDetails.category_subtitle}</p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img style={{marginTop: "6rem"}} src={categoryDetails.category_image} alt="imagem fofinhos linha completa" />
        </div>
      </div>
      {categoryDetails.products.map(productDetails => (
        <HeroProduto
          link={productDetails.variations[0].image}
          cor={productDetails.color}
          logo={productDetails.image}
          btnCor={"#35A2DC"}
          options={productDetails.variations} 
          caracteristicas={productDetails.topics}
        />
      ))}
  
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />

    </>
  )
}

export default Produto;