import React, { useState, useEffect } from 'react';
import Footer from "../../components/Footer/Footer.js";
import Navbar from "../../components/Menu/Menu.js";
import "./FaleConosco.css";

function FaleConosco() {
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const updateSectors = async () => {
      try {
        const response = await fetch('https://www.canoinhas.com.br/painel/api/contact/get-sectors');
        const data = await response.json();
        setSectors(data);
      } catch (error) {
        console.error('Erro ao obter dados da API:', error);
      }
    };
    updateSectors();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(!(selectedSector && selectedSubject && message && email && phone && cnpj));
  }, [selectedSector, selectedSubject, message, email, phone, cnpj]);

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length > 11) {
        value = value.slice(0, 11);
    }

    let formattedValue;

    if (value.length <= 10) {
        formattedValue = value.replace(/(\d{2})(\d{4})(\d*)/, "($1)$2-$3");
    } else {
        formattedValue = value.replace(/(\d{2})(\d{5})(\d*)/, "($1)$2-$3");
    }
    setPhone(formattedValue);
  };

  const handleCNPJChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length > 14) return;

    const formattedValue = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
    );
    setCnpj(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.canoinhas.com.br/painel/api/contact/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          phone: phone,
          cnpj: cnpj,
          subject: selectedSubject,
          sectors_id: selectedSector,
          message: message,
        }),
      });
      const data = await response.json();
      console.log('Resposta da submissão:', data);
      setShowSuccessMessage(true);

      setEmail('');
      setPhone('');
      setCnpj('');
      setSelectedSubject('');
      setSelectedSector('');
      setMessage('');
      
      setTimeout(() => setShowSuccessMessage(false), 10000);
    } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="BannerFaleConosco">
        <form className="formContato" onSubmit={handleSubmit}>
          <div className="formularioCard">
            <h1>Fale <br /><strong>Conosco</strong></h1>
            <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
              <option value="">Selecione um assunto</option>
              <option>Reclamação</option>
              <option>Elogio</option>
              <option>Sugestão</option>
              <option>Dúvida</option>
              <option>Reclamação</option>
              <option>Representação</option>
              <option>Outro</option>
            </select>
            <select value={selectedSector} onChange={(e) => setSelectedSector(e.target.value)}>
              <option value="">Selecione um setor</option>
              {sectors.map((sector) => (
                <option key={sector.id} value={sector.id}>{sector.name}</option>
              ))}
            </select>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
            <input type="text" value={phone} onChange={handlePhoneChange} placeholder="Telefone" />
            <input type="text" value={cnpj} onChange={handleCNPJChange} placeholder="CNPJ" />
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Mensagem" />
            <button type="submit" disabled={isButtonDisabled}>Enviar</button>

            <div className={`success-message ${showSuccessMessage ? 'active' : ''}`}>
              Sua mensagem foi enviada com sucesso! Obrigado por entrar em contato.
            </div>

          </div>
        </form>
        <div className="atendente">
          <img src="../../../assets/atendente.png" alt="mulher telemarketing" />
        </div>
      </div>
      <div className="sectionContatoVendas">
        <div className="cardContato">
          <h3>
            ESCRITÓRIO DE VENDAS
            SÃO PAULO - SP
          </h3>
          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(11) 5642-3700</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                mail
              </span>
            </i>
            <p>vendas@canoinhas.com.br</p>
          </div>
        </div>

        <div className="cardContato">
          <h3>
            ESCRITÓRIO DE VENDAS
            CURITIBA/PR
          </h3>
          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(41) 3402-1733</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                mail
              </span>
            </i>
            <p>comercial.cwb@canoinhas.com.br</p>
          </div>
        </div>
      </div>
      <div className="ciaFabrica">
        <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14321.560407545787!2d-50.3571881!3d-26.1839854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3f47e088edce358e!2sCia%20Canoinhas%20de%20Papel!5e0!3m2!1spt-BR!2sbr!4v1674803618854!5m2!1spt-BR!2sbr" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div className="bottomCiaFabrica">
          <h3>
            Fábrica Canoinhas - SC
          </h3>
          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(47) 3621 7000</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FaleConosco;
