import BtnOutline from "../BtnOutline/BtnOutline"
import "./RepresentanteSection.css"

function RepresentanteSection() {
  return (
    <div className="RepresentanteSection">
      <div className="SectionTitleEco">
        <h1>Encontre <strong>Representantes</strong></h1>
        <p>Tem interesse em nossos<br />
          produtos? contate nosso<br />
          time de vendas.</p>
        <BtnOutline text={"Confira"} link={"/representante"} />
      </div>
    </div>
  )
}

export default RepresentanteSection