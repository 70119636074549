import "./NoticeSection.css"
import NoticeCard from "../NoticeCard/NoticeCard";
import { useState, useEffect } from "react";

function NoticeSection() {

  const [news, setNews] = useState([]);

  async function updateNews () {
    const response = await fetch('https://www.canoinhas.com.br/painel/api/news/get');
    const data = await response.json();
    
    setNews(data);
  }

  useEffect(() => {
    updateNews();
  }, []);

  const newsList = news.map((newsItem) => (
    <div className="containerCard">
      <div className="BannerNotice">
        <img src={newsItem.image} alt="Capa da Notícia" />
      </div>
      <div className="titleNoticia">
        <h3>{newsItem.title}</h3>
      </div>
      <a href={'/Notice?ref=' + newsItem.id}>Confira</a>
    </div>
  ));

  return (
    <div className="NoticeSection">
      <div className="NoticeCards" >
        {newsList}
      </div>
    </ div>
  )
}

export default NoticeSection;