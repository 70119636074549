import "./manutaince.css"

function Manutaince() {
  return (
    <>
      <div class="container">
        <div>
          <img class="logo" src="../../assets/logo.jpeg" alt="logo da CIA CANOINHAS" />
        </div>
        <div class="aviso">
          <div class="content">
            <div>
              <h1>Nosso site está em manutenção</h1>
            </div>
            <div>
              <p>Mas você pode conferir nosso catálogo no botão abaixo, ou conferir as vagas de emprego.
              </p>
            </div>
            <div class="btn">
              <a class="btnDownload" href="../../../assets/catalogo-cia-canoinhas.pdf" download="catalogo-cia-canoinhas.pdf">
                <i class="icon">
                  <span class="material-symbols-outlined">
                    enable
                  </span>
                </i>Baixar Catálogo</a>
              <a class="btnJob" href="https://painel.umentor.com.br/inteligente_novos/?con_cod=cia44509&pla=5">
                <i class="icon">
                  <span class="material-symbols-outlined">
                    work
                  </span>
                </i>Trabalhe Conosco</a>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="unidade">
            <h4>FÁBRICA CANOINHAS - SC</h4>
            <div class="telefone">
              <i><span class="material-symbols-outlined">
                call
              </span></i>
              <p>(47) 3621-7000</p>
            </div>
            <div class="adress">
              <i>
                <span class="material-symbols-outlined">
                  location_on
                </span>
              </i>
              <p>BR-280, Parque Industrial,
                Canoinhas - SC, 89466-900</p>
            </div>
          </div>

          <div class="unidade">
            <h4>ESCRITÓRIO DE VENDAS
              SÃO PAULO - SP</h4>
            <div class="telefone">
              <i><span class="material-symbols-outlined">
                call
              </span></i>
              <p>(11) 5642-3700</p>
            </div>
            <div class="adress">
              <i>
                <span class="material-symbols-outlined">
                  mail
                </span>
              </i>
              <p>vendas@canoinhas.com.br</p>
            </div>
          </div>

          <div class="unidade">
            <h4>ESCRITÓRIO DE VENDAS
              CURITIBA/PR</h4>
            <div class="telefone">
              <i><span class="material-symbols-outlined">
                call
              </span></i>
              <p>(41) 3402-1733</p>
            </div>
            <div class="adress">
              <i>
                <span class="material-symbols-outlined">
                  mail
                </span>
              </i>
              <p>comercial.cwb@canoinhas.com.br</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Manutaince;