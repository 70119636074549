import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function Best() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Maciez e Absorção"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "20 Metros"
    },
    {
      icon: icone,
      text: "Maciez e Absorção"
    },
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "Multiuso"
    },
    {
      icon: icone,
      text: "Absorção e Resistência"
    },
  ]

  const options1 = [
    {
      imagem: "../../../../assets/BEST.png",
      qtde: "4",
      un: "Rolos",
      cor: "#2439AE"
    },
    {
      imagem: "../../../../assets/Best/Best 12 Rolo - 1896.png",
      qtde: "12",
      un: "Rolos",
      cor: "#2439AE"
    },
  ];

  const options2 = [
    {
      imagem: "../../../../assets/Best/Packshot_BEST_LITE_4_ROLOS.png",
      qtde: "4",
      un: "Rolos",
      cor: "#741960"
    },
    {
      imagem: "../../../../assets/Best/Packshot_BEST_LITE_12_ROLOS.png",
      qtde: "12",
      un: "Rolos",
      cor: "#741960"
    },
  ];

  const options3 = [
    {
      imagem: "../../../../assets/Best/Papel Toalha Best - 1234.png",
      qtde: "110",
      un: "Toalhas",
      cor: "#BB0A34"
    },
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro">
          <h1>Qualidade<br />
            <strong> e custo benefício</strong></h1>
          <p>
            Uma linha de produtos de alta qualidade que oferece <br />excelente custo benefício em papel higiênico  <br />e toalha de papel
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img src="../../../../assets/linhaBest.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
       {/* Primeira instância do componente HeroProduto */}
       <HeroProduto
        link={"../../../../assets/BEST.png"}
        cor={"#2D2975"}
        logo={"../../../../assets/bestLogo.png"}
        btnCor={"#2439AE"}
        options={options1}
        caracteristicas={infos}
      />

      {/* Segunda instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Best/Packshot_BEST_LITE_4_ROLOS.png"}
        cor={"#9a1f7f"}
        logo={"../../../../assets/logos/BEST_LITE_PNG.PNG"}
        btnCor={"#741960"}
        options={options2}
        caracteristicas={infos2}
      />

      {/* Terceira instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Best/Papel Toalha Best - 1234.png"}
        cor={"#d73b60"}
        logo={"../../../../assets/logos/PAPEL_TOALHA_BEST-01-01.png"}
        btnCor={"#BB0A34"}
        options={options3}
        caracteristicas={infos3}
      />
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />

    </>
  )
}

export default Best;