import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Autoplay(items) {

    var banners = [];

    function updateBannersList (arr) {
        if (!arr) {
            return;
        }
        arr.items.forEach((item, index) => {
            banners.push(
                <div style={{marginTop: "4rem"}} className="item" data-value={index + 1}>
                    <img className="banner-home" src={item} alt="Banner" />
                </div>
            )
        });
        
    }

    useEffect(() => {
        updateBannersList(items);
    }, [items]);

    return (
        <AliceCarousel
            autoPlay
            autoPlayStrategy="none"
            autoPlayInterval={4000}
            animationDuration={1000}
            animationType="fadeout"
            infinite
            touchTracking={false}
            disableDotsControls
            disableButtonsControls
            items={banners}
        />
    )
}

export default Autoplay;