import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function Fofinho60m() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "60 Metros"
    },
    {
      icon: icone,
      text: "Branquinho"
    },
    {
      icon: icone,
      text: "Macio"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "60 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Perfumado Cheirinho de bebê"
    },
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "60 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Perfumado Chá Verde"
    },
  ]

  const options1 = [
    {
      imagem: "../../../../assets/60m/4.png",
      qtde: "4",
      un: "Rolos",
      cor: "#a15eb3",
      visible: "#a15eb3"
    },
    {
      imagem: "../../../../assets/60m/12.png",
      qtde: "12",
      un: "Rolos",
      cor: "#a15eb3",
      visible: "#a15eb3"
    },
    {
      imagem: "../../../../assets/60m/16.png",
      qtde: "16",
      un: "Rolos",
      cor: "#a15eb3",
      visible: "#a15eb3"
    },
    {
      imagem: "../../../../assets/60m/24.png",
      qtde: "24",
      un: "Rolos",
      cor: "#a15eb3",
      visible: "#a15eb3"
    }
  ];
  const options2 = [
    {
      imagem: "../../../../assets/60m/4bb.png",
      qtde: "4",
      un: "Rolos",
      cor: "#722283",
      visible: "#722283"
    }
  ];
  const options3 = [
    {
      imagem: "../../../../assets/60m/4cv.png",
      qtde: "4",
      un: "Rolos",
      cor: "#722283",
      visible: "#722283"
    }
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro"  style={{marginTop: "6rem"}}>
        <h1>Fofinho<br />
            <strong> e suas versões</strong></h1>
          <p>
            Com versões neutro, cheirinho de bebê e chá verde<br />
            os papéis higiênicos da linha fofinho atendem a todos os gostos<br />
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img  style={{marginTop: "6rem"}} src="../../../assets/60mHeader.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
        {/* Sexta instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#722283"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#A0248E"}
        options={options1} 
        caracteristicas={infos}
      />
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#F55096"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#A0248E"}
        options={options2} 
        caracteristicas={infos2}
      />
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#00AD99"}
        logo={"../../../assets/fofinhoLogo.png"}
        btnCor={"#A0248E"}
        options={options3} 
        caracteristicas={infos3}
      />
      
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />
  

    </>
  )
}

export default Fofinho60m;