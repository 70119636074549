import React, { useState } from "react";
import BtnTrocaImg from "../BtnTrocaImg/BtnTrocaImg";
import "./HeroProduto.css";
import InfoSectionProduto from "../InfoSectionProduto/InfoSectionProduto";

function HeroProduto(props) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const options = props.options;

  function escurecerCor(hex, percent) {
    // Converter o código hexadecimal para RGB
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    r = Math.floor(r * (100 - percent) / 100);
    g = Math.floor(g * (100 - percent) / 100);
    b = Math.floor(b * (100 - percent) / 100);

    r = (r < 0) ? 0 : ((r > 255) ? 255 : r);
    g = (g < 0) ? 0 : ((g > 255) ? 255 : g);
    b = (b < 0) ? 0 : ((b > 255) ? 255 : b);

    const novaCor = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    
    return novaCor;
  }

  const buttonColor = escurecerCor(props.cor, 30)

  const changeImage = (newIndex) => {
    setCurrentImageIndex(newIndex);
  };

  return (
    <div className="containerHero" style={{ backgroundColor: `${props.cor}` }}>
      <div className="imgProdutoHero">
        <img src={options[currentImageIndex].image} alt="fofinho neutro" />
        <div className="bxImage">
          <a class="baixarimage" href={options[currentImageIndex].image} download="image.png">
              <i class="icon">
                <span class="material-symbols-outlined">
                  enable
                </span>
              </i>
            Baixar Imagem
          </a>
        </div>
      </div>
      <div className="infosProduto">
        <img src={props.logo} alt="Logo" />
        {props.caracteristicas.map((caracteristica, index) => (
          <div className="caracteristicas" key={index}>
            <div className="iconCaracteristica">
              <img style={{ width: "2rem" }} src={caracteristica.icon} alt="icone" />
            </div>
            <p>{caracteristica.name}</p>
          </div>
        ))}
        <div className="btnTrocaHero">
          {options.map((option, index) => (
            option.image && (
              <BtnTrocaImg
                key={index}
                imagem={option.image}
                name={option.name}
                cor={buttonColor}
                onClick={() => changeImage(index)}
              />
            )
          ))}
        </div>
      </div>
    </div>
  );
}

export default HeroProduto;
