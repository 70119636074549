import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Manutaince from "./manutaince/manutaince";

import App from "./App";
import FaleConosco from "./pages/FaleConosco/FaleConosco";
import Fofinho from "./pages/Fofinho/Fofinho";
import Produto from "./pages/Produto/Produto";
import NoticePage from "./pages/Notice/Notice";
import FofinhoAbsolut from "./pages/pageProdutos/FofinhoAbsolut/FofinhoAbsolut";
import Sorella from "./pages/pageProdutos/Sorella/Sorella";
import Representante from "./pages/Representante/Representante";
import History from "./pages/History/History";
import FofinhoAbsolutTriplo from "./pages/pageProdutos/FofinhoAbsolutTriplo/FofinhoAbsolutTriplo";
import Sobre from "./pages/Sobre/Sobre";
import Bambino from "./pages/pageProdutos/Bambino/Bambino";
import Best from "./pages/pageProdutos/Best/Best";
import Fofinhofd from "./pages/Fofinhofolhadupla/Fofinhoduplo";
import Evis from "./pages/pageProdutos/Evis/Evis";
import Institucional from "./pages/pageProdutos/Institucional/Institucional";
import Fofinho60m from "./pages/pageProdutos/Fofinho60m/Fofinho60m";

function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/manutaince" element={<Manutaince />} />
        <Route path="/produto" element={<Produto />} />
        <Route path="/fofinho" element={<Fofinho />} />
        <Route path="/fofinho60m" element={<Fofinho60m />} />
        <Route path="/evis" element={<Evis />} />
        <Route path="/institucional" element={<Institucional/>} />
        <Route path="/fofinhoFolhaDupla" element={<Fofinhofd />} />
        <Route path="/fofinhoAbsolut" element={<FofinhoAbsolut />} />
        <Route path="/sorella" element={<Sorella />} />
        <Route path="/FaleConosco" element={<FaleConosco />} />
        <Route path="/Notice" element={<NoticePage />} />
        <Route path="/representante" element={<Representante />} />
        <Route path="/historia" element={<History />} />
        <Route path="/fofinhoAbsolutPremium" element={<FofinhoAbsolutTriplo />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/Bambino" element={<Bambino />} />
        <Route path="/Best" element={<Best />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Rotas;
