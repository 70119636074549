import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function Evis() {

  const options = [
    {
      imagem: "../../../../assets/Evis/EVIS_32_ROLOS.png",
      qtde: "32",
      un: "Rolos",
      cor: "#C6445F"
    },
  ];

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Maciez e Absorção"
    },
    {
      icon: icone,
      text: "Compacto"
    },
  ]

  return (
    <>
      <Navbar />
      <HeroProduto
        link={""}
        cor={"#92173F"}
        logo={"../../../../assets/Evis/evisBranco.png"}
        btnCor={"#C6445F"}
        options={options}
        caracteristicas={infos}
      />
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />

    </>
  )
}

export default Evis;