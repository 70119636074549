import "./History.css"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Footer from "../../components/Footer/Footer";
import SocialMediaSection from "../../components/SocialMediaSection/SocialMediaSection";
import Navbar from "../../components/Menu/Menu";
import { Margin } from "@mui/icons-material";

function History() {
  const timelineContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
  };

  const timelineItemStyle = {
    flex: 1,
    border: '0px solid #009BDB',
    borderRadius: '5px',
    marginBottom: '20px',
    display: 'flex',
  };

  const timelineDateStyle = {
    background: '#009BDB',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    minWidth: '100px',
  };

  const timelineContentStyle = {
    flex: 1,
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',   
    justifyContent: "center"   
  };

  const imageStyle = {
    width: '100%',
  };
  
  return (
    <>
      <Navbar />
      <div className="historyHeader">
      
      </div>
      <VerticalTimeline
        lineColor={'#009BDB'}>

         <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1983</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Onde tudo começa</h1>
          <p style={{ color: "#fff" }}>
            Fundada em 30 de maio de 1983, a Cia Canoinhas assumiu as antigas instalações da Safelca Canoinhas S/A. Com uma área construída de 400m² e 25 colaboradores em um terreno de 56 mil metros quadrados, possuía uma única máquina de papel com produção aproximada de 240 ton/mês. As bobinas jumbo produzidos na planta eram transferidas para São Paulo para serem convertidas em papel toalha.<br></br>
            As primeiras linhas de conversão iniciaram na Cia Canoinhas em meados de 1987, os rolinhos eram embalados manualmente um a um em papel de seda.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/Foto1.png"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1989</h3>}
        >
          <h1 className="vertical-timeline-element-title">As primeiras linhas</h1>
          <p>
          Em 1989 a empresa modernizou sua linha de conversão adquirindo uma rebobinadeira automática, passou a produzir em embalagens com 4 rolos. Nesta mesma época a empresa começou a produzir papel toalha em rolos de 50m. Neste mesmo ano teve ainda a instalação da primeira caldeira para produção de vapor a partir da biomassa (cavaco). 
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/Foto2.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1990</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Investimentos</h1>
          <p style={{ color: "#fff" }}>
          Em 1990 mesmo diante da instabilidade econômica do país a empresa investiu na aquisição de uma segunda máquina de papel com objetivo de praticamente triplicar a sua capacidade produtiva, alcançando assim uma produção de aproximadamente 11.000 toneladas de papel ano. Uma guardanapeira foi adquirida e incorporada ao setor de conversão da empresa no mesmo período.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto3.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1992</h3>}
        >
          <h1 className="vertical-timeline-element-title">Um grande passo</h1>
          <p>
          O ano de 1992 foi um marco no desenvolvimento da Cia Canoinhas. 
Neste ano foi firmado um contrato com a empresa Dixie Lalekla e a Cia Canoinhas passou a converter os produtos interfolhados da Dixie, guardanapos com impressão e guardanapo para parte da rede MC Donald’s. Estes produtos representavam 50% da produção da Cia Canoinhas e essa parceria foi essencial para a estabilização financeira e crescimento da empresa. O contrato foi encerrado no ano de 2002.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto4.jpeg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1993"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1993</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Linha Fofinho</h1>
          <p style={{ color: "#fff" }}>
          Em 1993 teve início a fabricação dos produtos da linha Fofinho, produzido a partir de aparas de melhor qualidade, gerando assim um papel higiênico mais branco de melhor qualidade, tornando-se a principal marca dos produtos da empresa.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto5.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1997</h3>}
        >
          <h1 className="vertical-timeline-element-title">Avançar é Preciso</h1>
          <p>
          Em 1997 a empresa sentiu a necessidade de complementar a educação de seus colaboradores, nesta época grande parte dos colaboradores possuíam ensino fundamental e médio incompletos, foi quando em parceria com o SESI e a 18ª CRE foram criadas as Telessalas do programa do Telecurso 2000 a Cia Canoinhas disponibilizou o local e o material didático, o programa foi intitulado “Avançar é Preciso”.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto6.jpeg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1999"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>1999</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Um grande marco</h1>
          <p style={{ color: "#fff" }}>
          Em 1999 a empresa adquiriu uma nova linha de conversão de higiênicos que possibilitou a empresa ampliar seu portfólio de produtos que utilizavam duas camadas em sua composição como papel higiênico Fofinho Folha Dupla e as Toalhas de Papel da marca Sorella.          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto7.png"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2000</h3>}
        >
          <h1 className="vertical-timeline-element-title">Mais Investimentos</h1>
          <p>
          No ano 2000 foram inauguradas as ampliações do sistema de tratamento de efluentes, com a implantação do tratamento microbiológico e lagoas de aeração de 15.000m³, totalmente impermeabilizadas, com capacidade para tratamento de 60.000 litros por hora. Em 2009 houve a substituição deste pelo sistema de Lodo Ativado que utiliza reatores biológicos e um sistema de aeração em uma única lagoa. O novo sistema ocupa menor espaço, menor tempo de retenção hidráulico e maior eficiência no tratamento de efluentes. 
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto8.png"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2001</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Mais Melhorias</h1>
          <p style={{ color: "#fff" }}>
          Em 2001 houve a substituição das duas linhas de fibras secundárias existentes por uma única linha nova, com muito mais tecnologia que permitiu a empresa utilizar matérias primas com maior quantidade de contaminantes e obter um produto final de melhor qualidade.          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2004</h3>}
        >
          <h1 className="vertical-timeline-element-title">Grande Avanço</h1>
          <p>
          Em 2004 outro grande avanço com relação ao aumento da produtividade da empresa foi a instalação de uma subestação elétrica de 138KV. A energia provém de uma linha exclusiva de 138KV para a Cia Canoinhas e outras duas empresas da região. O maior nível de tensão possibilitou uma energia mais estável e de menor custo. 
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto10.png"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2008</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Mais investimentos</h1>
          <p style={{ color: "#fff" }}> 
            O ano de 2008 foi marcado por uma grande expansão da capacidade produtiva da empresa. A instalação da máquina de papel 3, com capacidade para produzir até 60 ton/dia, permitiu a empresa alcançar uma produção anual de mais de 30.000 toneladas ao ano. Para suprir uma expansão deste porte a empresa precisou investir também em uma segunda linha de fibras secundárias e uma segunda caldeira para produção de vapor. A máquina de papel 3 possui uma tecnologia diferente no sistema de formação de papel, na comparação com as máquinas 1 e 2, o crescent former, que permite uma distribuição mais uniforme das fibras, formando uma folha mais homogênea, proporcionando maior maciez e qualidade ao produto final.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto11.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2008</h3>}
        >
          <h1 className="vertical-timeline-element-title">Ampliações</h1>
          <p>
          Prevendo esse aumento significativo da produção de papel a empresa adquiriu duas novas linhas de conversão, sendo uma para folha simples e a outra que possibilitou oferecer um papel higiênico folha dupla de melhor qualidade e os chamados packs, que são os pacotes com 8, 12, 16 e 24 rolos. 
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto12.jpg"/>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2011</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Mais tecnologia</h1>
          <p style={{ color: "#fff" }}>
          Buscando alcançar novos mercados a Cia Canoinhas lançou em 2011 um novo produto o Fofinho Absolute. Produzido exclusivamente a partir de fibras virgem de celulose de eucalipto o que conferiu uma maciez ainda superior ao papel e suavidade absoluta, sem perder a resistência que um papel higiênico necessita. Com uma embalagem desenhada exclusivamente para exibir ainda mais o produto e utilizando as cores preto, bordô e um toque de dourado conferindo a sofisticação que ele merece.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto13.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2012</h3>}
        >
          <h1 className="vertical-timeline-element-title">Reforma</h1>
          <p>
            A melhora da economia tornou os consumidores mais exigentes, em 2012 a empresa investiu na reforma da máquina de papel 1, onde substituiu o sistema de formação de mesa plana pelo mesmo sistema já existente na máquina de papel 3, o crescent former. 
            Essa reforma gerou aumento da produtividade e qualidade superior no papel.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto14.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="1983"
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2012</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Mais produção</h1>
          <p style={{ color: "#fff" }}>
          No segundo semestre de 2012 teve a conclusão e start-up da Linha 7 da conversão, uma linha 100% automatizada e com tecnologia de ponta, possui capacidade para produção mensal de 200 mil fardos de Papel Higiênico Folha Dupla mês.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto15.jpg"/>
          </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2014</h3>}
        >
          <h1 className="vertical-timeline-element-title">Um ano marcante</h1>
          <p>
            O início do ano de 2014 foi mais um marco na gestão ambiental da Cia Canoinhas, foi iniciado as atividades do Calcinador de Lodo, equipamento que permite a redução do resíduo gerado na fabricação de papel cuja matéria prima é a aparas de papel.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto16.jpg"/>

        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2015</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">A grande expansão</h1>
          <p style={{ color: "#fff" }}>
          No ano de 2015 a empresa começou a se preparar para mais um aumento significativo em sua produção, para isto investiu em uma nova caldeira com capacidade de produção de vapor de 25t/h. Esta supre toda a demanda de vapor das 3 máquinas existentes e permitirá a instalação de uma quarta máquina de papel.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto17.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2015</h3>}
        >
          <h1 className="vertical-timeline-element-title">Mais reformas</h1>
          <p>
          Com o intuito de igualar o padrão de papel produzido nas 3 máquinas existentes, em 2015 foi realizado a reforma do sistema de formação da máquina de papel 2, substituindo a caixa de entrada e mesa plana pelo sistema de crescent former.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto18.jpg"/>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2015..</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Buscando novos mercados</h1>
          <p style={{ color: "#fff" }}>
          No mesmo ano e com o mesmo pensamento no aumento da capacidade produtiva a empresa adquiriu uma nova linha de conversão de higiênicos. A linha 8 possui um sistema de gofragem diferente que confere ainda mais maciez ao produto final. No mesmo momento a empresa adquiriu sua segunda linha de guardanapos, também 100% automatizada. 
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto19.jpg"/>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2016</h3>}
        >
          <h1 className="vertical-timeline-element-title">Mais avanços</h1>
          <p>
          Com o intuito de otimizar o transporte dos fardos da conversão até a doca de estocagem e diminuir o trânsito de empilhadeiras na área fabril, a empresa investiu em 2016 em um sistema de esteiras transportadoras de fardos, que juntamente com sistema de identificação faz a separação dos diferentes produtos destinando-os a cada local de paletização.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2017</h3>}
          lineColor={"#000"}
        >
          <h1 id="ambiental" className="vertical-timeline-element-title">Mais eficiência</h1>
          <p style={{ color: "#fff" }}>
            Visando melhorar a gestão da qualidade, aumentar o desempenho da empresa, a satisfação dos nossos clientes e alcançar novos níveis de excelência, em 2017, iniciou-se o processo de certificação do Sistema de Gestão da Qualidade ISSO 9001:2015. Para implementação e adequação à norma, era preciso atender todos os requisitos nela estabelecidos, e para isso, fez-se necessário a revisão de toda documentação da empresa, realizar treinamentos com gestores e líderes para conscientizar sobre a importância da certificação, e disseminar que essa conquista somente seria possível com a participação de todos os colaboradores. Em Julho de 2018 ocorreu a auditoria de certificação, com a recomendação dos auditores pela certificação do SGQ da empresa, em setembro do mesmo ano recebemos o certificado da ISO 9001:2015 através da ABS Group.
          </p>
          <img style={{marginTop: '2rem', width:'100%'}} src="../../assets/historia/foto22.jpg"/>

        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2002 - 2006"
          contentStyle={{ boxShadow: "0 3px 0 #009bda" }}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2020</h3>}
        >
          <h1 className="vertical-timeline-element-title">Pandemia</h1>
          <p>
          Mesmo com o advento da Pandemia da COVID-19 causada pelo Coronavírus (Sars-cov 2); que vitimou mais de 1 milhão de pessoas no Brasil e no mundo; fez a economia mundial desacelerar devido às medidas de isolamento social para tentar conter a propagação do vírus; gerou escassez de matérias primas como plásticos, papelão e alguns metais afetando diferentes cadeias de suprimentos nos mais variados setores da indústria, fazendo assim a inflação disparar a nível nacional, o ano de 2020 na Cia Canoinhas foi marcado pela reforma e substituição de partes das linhas de conversão 1, 2, 4, 5 e 6, tornando assim todas as 8 linhas de Higiênicos 100% automatizadas.           </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#009BDB', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          animate={true}
          iconStyle={{ background: '#009BDB', color: '#fff', padding: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          icon={<h3>2021</h3>}
          lineColor={"#000"}
        >
          <h1 className="vertical-timeline-element-title">Mais qualidade e produtividade</h1>
          <p style={{ color: "#fff" }}>
          Logo no início de 2021 foi iniciado a produção com a nova Linha de Conversão de Higiênicos 9 – Constellation, a qual dispõe da mais recente tecnologia de rebobinamento em Linhas de Conversão, que proporciona melhor uniformidade do início ao fim do log, com capacidade produtiva de 600 m/min. A nova linha de higiênico otimizou a capacidade produtiva, proporcionando ainda mais qualidade ao produto acabado, e por possuir três desenroladores permitiu a produção do tão esperado produto Folha Tripla Premium, o qual possui em suas características ultra maciez e suavidade absoluta em suas três camadas de papel. Com a inserção destes aumentamos nosso mix de produtos, sendo possível alcançar novos nichos de mercado, reforçando o comprometimento em continuar fornecendo produtos de alta qualidade que atendam aos requisitos dos nossos clientes e consumidores.          </p>
        </VerticalTimelineElement>

      </VerticalTimeline>
      <SocialMediaSection />
      <Footer />
    </>

  )
}

export default History;