import "./Representante.css";
import { MapBrazil } from 'react-brazil-map'
import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Menu/Menu";

function Representante() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");

    if (value.length > 11) {
        value = value.slice(0, 11);
    }

    let formattedValue;

    if (value.length <= 10) {
        formattedValue = value.replace(/(\d{2})(\d{4})(\d*)/, "($1)$2-$3");
    } else {
        formattedValue = value.replace(/(\d{2})(\d{5})(\d*)/, "($1)$2-$3");
    }
    setPhone(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.canoinhas.com.br/painel/api/contact/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          phone: phone,
          cnpj: 'N/A',
          subject: 'Procura de Representante',
          sectors_id: 7,
          message: 'Nome: ' + name + '<br> UF: ' + distritc + '<br><br>' + message
        }),
      });
      const data = await response.json();
      console.log('Resposta da submissão:', data);
      setShowSuccessMessage(true);

      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      
      setTimeout(() => setShowSuccessMessage(false), 10000);
    } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
    }
  };

  useEffect(() => {
    setIsButtonDisabled(!(name && message && email && phone));
  }, [name, message, email, phone]);

  const [distritc, setDistrict] = useState('')

  return (
    <>
      <Navbar />
      <div className='representantePage'>
        <div className="textPageRepre">
          <h1>Encontre um <br />
            <strong>representante</strong></h1>
          <p>Para facilitar seu atendimento, a Cia Canoinhas dispõe de uma rede de representantes<br />
            por todo Brasil. Para localizar o mais próximo de você entre em contato pelos telefones<br />
            ou e-mails abaixo.</p>

          <Popup trigger={<button className="button"> Procurar Representante</button>} modal>
            <form className="formularioCard" onSubmit={handleSubmit}>
              <h1>Preencha o <br /><strong>Formulário</strong></h1>
              <p>O time de vendas responsável entrará em contato com você o mais breve possível!</p>
              <br></br>
              <p>Estado selecionado: {distritc}</p>
              <input type="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Digite seu nome completo" />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Digite seu email" />
              <input type="phone" value={phone} onChange={handlePhoneChange} placeholder="Digite seu telefone com o DDD" />
              <textarea type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Mensagem" />
              <button type="submit" onSubmit={console.log(distritc, name, email, phone, message)}>Enviar</button>

              <div className={`success-message ${showSuccessMessage ? 'active' : ''}`}>
                Sua mensagem foi enviada com sucesso! Obrigado por entrar em contato.
              </div>
            </form>
          </Popup>
        </div>
        <Popup trigger={<div className="mapaBrasil">
          <MapBrazil width={500} height={500} fill='#009BDB' colorLabel='#FFF' onChange={setDistrict} />
          <p>Estado selecionado: <strong>{distritc}</strong></p>
        </div>
        } modal>
          <form className="formularioCard" onSubmit={handleSubmit}>
            <h1>Preencha o <br /><strong>Formulário</strong></h1>
            <p>O time de vendas responsável entrará em contato com você o mais breve possível!</p>
            <br></br>
            <p>Estado selecionado: {distritc}</p>
            <input type="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Digite seu nome completo" />
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Digite seu email" />
              <input type="phone" value={phone} onChange={handlePhoneChange} placeholder="Digite seu telefone com o DDD" />
              <textarea type="text" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Mensagem" />
              <button type="submit" disabled={isButtonDisabled}>Enviar</button>
          </form>
        </Popup>

      </div>
      <Footer />
    </>

  )
}

export default Representante;