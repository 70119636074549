import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function FofinhoAbsolut() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "30 Metros"
    },
    {
      icon: icone,
      text: "Suavidade Absoluta"
    },
    {
      icon: icone,
      text: "Alta Maciez e Absorção"
    },
    {
      icon: icone,
      text: "Branquinho"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha tripla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "20 Metros"
    },
    {
      icon: icone,
      text: "Suavidade Absoluta"
    },
    {
      icon: icone,
      text: "Ultra maciez e absorção"
    },
    {
      icon: icone,
      text: "Branquinho"
    },  
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "60 Metros"
    },
    {
      icon: icone,
      text: "Suavidade Absoluta"
    },
    {
      icon: icone,
      text: "Alta maciez e absorção"
    },
    {
      icon: icone,
      text: "Branquíssimo"
    },  
  ]

  const options1 = [
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 4 Rolos - 1344.png",
      qtde: "4",
      un: "Rolos",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 8 Rolos -1345.png",
      qtde: "8",
      un: "Rolos",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 12 Rolos - 1346.png",
      qtde: "12",
      un: "Rolos",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 16 Rolos - 1347.png",
      qtde: "16",
      un: "Rolos",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 24 Rolos - 1348.png",
      qtde: "24",
      un: "Rolos",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute 12 Rolos Compacto - 1349.png",
      qtde: "12 Rolos",
      un: "Compacto",
      cor: "#3A3A3A",
      visible: "#3A3A3A"
    }
  ];

  const options2 = [
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute-FT-4-Rolos---1825.png",
      qtde: "4",
      un: "Rolos",
      cor: "#002C72",
      visible: "#002C72"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/Absolute FT 12 Rolos - 1826.png",
      qtde: "12",
      un: "Rolos",
      cor: "#002C72",
      visible: "#002C72"
    }
  ];

  const options3 = [
    {
      imagem: "../../../../assets/FofinhoAbsolute/1ABSOLUTE_FOFINHO_6ROLOS_60M_3.png",
      qtde: "4",
      un: "Rolos",
      cor: "#000",
      visible: "#000"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/1ABSOLUTE_FOFINHO_6ROLOS_60M_1.png",
      qtde: "6",
      un: "Rolos",
      cor: "#000",
      visible: "#000"
    },
    {
      imagem: "../../../../assets/FofinhoAbsolute/1ABSOLUTE_FOFINHO_6ROLOS_60M_2.png",
      qtde: "12",
      un: "Rolos",
      cor: "#000",
      visible: "#000"
    },
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro">
          <h1>Maciez e<br />
            <strong> Suavidade</strong></h1>
          <p>
            Pensado para o conforto de toda a sua família.<br /> Possi ultra maciez e máxima absorção sem <br />perder a resistência.
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img src="../../../../assets/montagemabs.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
       {/* Quarta instância do componente HeroProduto */}
       <HeroProduto
        link={"../../../../assets/FOFINHO-ABSOLUT.png"}
        cor={"#9B9B9D"}
        logo={"../../../../assets/fofinhoAbs-logo.png"}
        btnCor={"#3A3A3A"}
        options={options1}
        caracteristicas={infos}
      />

      {/* Quinta instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Absolute 4 Rolos - 1344.png"}
        cor={"#5D689A"}
        logo={"../../../../assets/logoAbsPremium.png"}
        btnCor={"#002C72"}
        options={options2}
        caracteristicas={infos2}
      />

<HeroProduto
        link={"../../../../assets/Absolute 4 Rolos - 1344.png"}
        cor={"#5b1025"}
        logo={"../../../../assets/fofinhoAbs-logo.png"}
        btnCor={"#a51a3f"}
        options={options3}
        caracteristicas={infos3}
      />
    
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      
      <Footer />

    </>
  )
}

export default FofinhoAbsolut;