
import BtnOutline from "../../components/BtnOutline/BtnOutline";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Menu/Menu";
import NoticeSection from "../../components/NoticeSection/NoticeSection";
import "./Sobre.css"

function Sobre() {
  return (
    <div>
      <Navbar />
      <div className="banner">
        <h1>Somos a <br /> Cia Canoinhas de Papel</h1>
      </div>
      <div className="atualmente">
        <img src="../../../assets/fabril-canoinhas.jpeg" alt="" />
        <div className="textAtualmente">
          <h1>A empresa hoje</h1>
          <p id="altura">A história da Cia Canoinhas de Papel começa em 1983, quando inicia no ramo de atividade de fabricação de papéis descartáveis. 
            Atualmente a Cia Canoinhas conta com 720 colaboradores diretos. Mantém uma capacidade produtiva ativa em torno de 94.000 ton/ano em uma área construída de 70.000m².
            Visando a melhoria contínua de todos os nossos processos, bem como da organização, a Cia Canoinhas é certificada na ISO 9001:2015 – Sistema de Gestão da Qualidade e possui a Certificação da Cadeia de Custódia FSC®, norma internacional que visa promover práticas de manejo florestal responsável.
            O objetivo da organização é suprir a demanda dos estados brasileiros, seja através de grandes redes de comercialização, institucionais, distribuidores ou pequenos mercados, para isso a empresa conta com Escritórios de Vendas nas cidades de São Paulo, Curitiba e Porto Alegre, e aproximadamente 160 Representantes Comerciais espalhados pelas diversas regiões do País.
            A Cia Canoinhas é uma empresa de grande importância para o desenvolvimento econômico da região e dedica atenção especial a preservação e controle do meio ambiente, projetos sociais e sustentabilidade.</p>
        </div>
      </div>
      <div className="cardsMVV">
        <div className="mmv">
          <div className="iconmmv">
            <span class="material-symbols-outlined">
              ads_click
            </span>
          </div>
          <h3>Missão</h3>
          <span color="#fff">
            Produzir papéis para fins sanitários de alta qualidade, atuando de forma sustentável, atendendo e superando as expectativas dos nossos clientes e gerando valores para nossos parceiros.          </span>
        </div>


        <div className="mmv">
          <div className="iconmmv">
            <span class="material-symbols-outlined">
              favorite
            </span>
          </div>
          <h3>Valores</h3>
          <span color="#fff">
            - Ética<br />
            - Comprometimento<br />
            - Respeito às pessoas<br />
            - Superação de Resultados<br />
            - Melhoria Contínua<br />
            - Inovação<br />
            - Sustentabilidade<br />
          </span>
        </div>


        <div className="mmv">
          <div className="iconmmv">
            <span class="material-symbols-outlined">
              visibility
            </span>
          </div>
          <h3>Visão</h3>
          <span color="#fff">
            Ser referência pela qualidade dos nossos produtos e satisfação do mercado consumidor.           </span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4rem" }}>
        <iframe width="1080" height="450" src="https://www.youtube.com/embed/tB0T-53sZrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div>
        <div className="vagas">
          <div className="titleVagas">
            <h1>Trabalhe conosco</h1>
            <span>Clique no botão abaixo e confira as vagas disponíveis</span>
            <BtnOutline text={"Trabalhe Conosco"} link={"https://painel.umentor.com.br/inteligente_novos/?con_cod=cia44509&pla=5"} />
          </div>
          <div className="imgVagas">
            <img src="../assets/trabalheConosco.jpeg" alt="" />
          </div>
        </div>
      </div>
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      <NoticeSection />
      <Footer />
    </div>
  )
}

export default Sobre;