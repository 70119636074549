import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function Sorella() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      icon: icone,
      text: "Alta Absorção"
    },
    {
      icon: icone,
      text: "Multiuso"
    },
    {
      icon: icone,
      text: "Alta Resistência"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Dupla de Alta Qualidade"
    },
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Máxima Absorção"
    },
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Alta Resistência"
    },
    {
      icon: icone,
      text: "Multiuso"
    },
    {
      icon: icone,
      text: "100% Celulose"
    },
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folhas Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "Maciez e Absorção"
    },
    {
      icon: icone,
      text: "100% Celulose"
    },
  ]

  const options1 = [
    {
      imagem: "../../../../assets/Sorella/Sorella 120 fls - 1051.png",
      qtde: "120",
      un: "Folhas",
      cor: "#AC2931",
      visible: "#AC2931"
    }
  ];

  const options2 = [
    {
      imagem: "../../../../assets/Sorella/Sorella Absolute - 1222.png",
      qtde: "110",
      un: "Toalhas",
      cor: "#000",
      visible: "#EDC773"
    }
  ];

  const options3 = [
    {
      imagem: "../../../../assets/Sorella/Sorella Econômica - 1210.png",
      qtde: "110",
      un: "Toalhas",
      cor: "#00195D",
      visible: "#EDC773"
    }
  ];

  const options4 = [
    {
      imagem: "../../../../assets/Sorella/22x23.png",
      qtde: "50 Folhas",
      un: "22x23cm",
      cor: "#AC2931",
      visible: "#EDC773"
    },
    {
      imagem: "../../../../assets/Sorella/30x305.png",
      qtde: "50 Folhas",
      un: "30x30,5cm",
      cor: "#AC2931",
      visible: "#EDC773"
    },
    {
      imagem: "../../../../assets/Sorella/pacotemenor.png",
      qtde: "16 Pacotes",
      un: "22x23cm",
      cor: "#AC2931",
      visible: "#EDC773"
    },
    {
      imagem: "../../../../assets/Sorella/pacotemaior.png",
      qtde: "6 Pacotes",
      un: "30x30,5cm",
      cor: "#AC2931",
      visible: "#EDC773"
    },
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro" style={{marginTop: "4rem"}}>
          <h1>Absorção e<br />
            <strong>Resistência</strong></h1>
          <p>
            Uma linha desenvolvida com elevados padrões<br /> de resistência e absorção para atender às<br /> necessidades diárias.
          </p>
        </div>
        <div className="imgSorella" style={{marginTop: "4rem"}}>
          <img src="../../../../assets/sorellaLinhaCompleta.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
      {/* Primeira instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/sorella.png"}
        cor={"#C26666"}
        logo={"../../../../assets/sorellaLogo.png"}
        btnCor={"#AC2931"}
        options={options1} 
        caracteristicas={infos}
      />

      {/* Segunda instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Sorella/Sorella Absolute - 1222.png"}
        cor={"#181818"}
        logo={"../../../../assets/sorellaLogo.png"}
        btnCor={"#EDC773"}
        options={options2}
        caracteristicas={infos2}
      />

      {/* Terceira instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Sorella/Sorella Econômica - 1210.png"}
        cor={"#103e89"}
        logo={"../../../../assets/sorellaLogo.png"}
        btnCor={"#EDC773"}
        options={options3}
        caracteristicas={infos}
      />
      <HeroProduto
        link={"../../../../assets/Sorella/Sorella Econômica - 1210.png"}
        cor={"#C26666"}
        logo={"../../../../assets/sorellaLogo.png"}
        btnCor={"#AC2931"}
        options={options4}
        caracteristicas={infos3}
      />
      
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />

    </>
  )
}

export default Sorella;