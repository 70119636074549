import "./CardProduto.css"

function Cardproduto(props) {
  return (
    <>
      <a href={props.link}>
        <div className="modelCard" style={{ backgroundColor: `${props.cor}` }}>
          <img className="logoProduto" src={props.logo} alt="logo produto" />
        </div>
      </a>

    </>
  )
}

export default Cardproduto;