import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Menu/Menu";
import SocialMediaSection from "../../components/SocialMediaSection/SocialMediaSection";
import "./Notice.css";

function NoticePage() {
  const [newsDetails, setNewsDetails] = useState({});

  async function updateNewsDetails() {
    const queryParams = new URLSearchParams(window.location.search);
    const reference = queryParams.get('ref');

    const response = await fetch('https://www.canoinhas.com.br/painel/api/news/get-one?id=' + reference);
    const data = await response.json();
    
    setNewsDetails(data);
  }

  useEffect(() => {
    updateNewsDetails();
  }, []);

  return (
    <div className="pageNotice">
      <Navbar />
      <div className="Titulo" >
        <h1 style={{marginTop:"8rem"}}>
          {newsDetails.title}
        </h1>
      </div>
      <div className="bannerNoticia">
        <img src={newsDetails.image} alt={newsDetails.image_subtitle} />
      </div>
      <div className="legenda">
        <p>{newsDetails.image_subtitle}</p>
      </div>
      <div className="contentNotice">
        <div dangerouslySetInnerHTML={{ __html: newsDetails.content }}></div>
      </div>
      <SocialMediaSection />
      <Footer />
    </div>
  )
}

export default NoticePage;
