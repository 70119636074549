import { useRef, useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import "./Menu.css";
import * as React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';

function Navbar() {
  const navRef = useRef();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [submenuAnchorEl, setSubmenuAnchorEl] = React.useState(null); // State for submenu
  const submenuOpen = Boolean(submenuAnchorEl); // State for submenu
  const handleClickSubmenu = (event, subCategoriesParam) => {
    setSubmenuAnchorEl(event.currentTarget);
    setSubCategories(subCategoriesParam);
  };
  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null);
  };

  const theme = createTheme();
  theme.typography.h3 = {};

  async function updateCategories () {
    fetch('https://www.canoinhas.com.br/painel/api/categories/get').then(function(response) {
      return response.json();
    }).then(function(response) {
      setCategories(response);
    }).catch(function(e){
      console.log(e);
    });
  }

  useEffect(() => {
    updateCategories();
  }, []);

  return (
    <header>
      <div className="NavLogo">
        <img src="../../assets/logo.jpeg" alt="logo Cia Canoinhas" />
      </div>
      <div className="navBtn">
        <nav ref={navRef}>
          <a href="/">Home</a>
          <a href="/sobre">A Empresa</a>
          <a href="/historia">Nossa História</a>
          <ThemeProvider theme={theme}>
            <Button
              sx={{
                fontFamily: "'Roboto', 'sans-serif'",
                textTransform: "none",
                fontSize: "16px",
                color: "#475B65",
                '&:hover, &.Mui-focusVisible': {
                  backgroundColor: 'transparent',
                  transform: 'none'
                },
              }}
              aria-controls="produtos-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <strong className="strong">Produtos</strong> <AiFillCaretDown />
            </Button>
            <Menu
              id="produtos-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {categories.map(category => (
                <>
                  {(category.children) ?
                    <MenuItem onClick={(clickEvent) => handleClickSubmenu(clickEvent, category.children)} >
                        {category.name} <ArrowDropDownIcon />
                    </MenuItem>
                    :
                    <MenuItem onClick={handleClose}>
                      <a style={{ textDecoration: "none", fontWeight: "400", color: "#475B65" }} 
                        href={"/Produto?ref=" + category.id}>{category.name}
                      </a>
                    </MenuItem>
                  }
                </>
              ))}
            </Menu>
            <Menu
              id="submenu-fofinho"
              anchorEl={submenuAnchorEl}
              open={submenuOpen}
              onClose={handleCloseSubmenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {subCategories.map(subCategory => (
                <MenuItem onClick={handleCloseSubmenu}>
                  <a style={{ textDecoration: "none", fontWeight: "400", color: "#475B65" }} href={"/Produto?ref=" + subCategory.id}>{subCategory.name}</a>
                </MenuItem>
              ))}
            </Menu>
          </ThemeProvider>
          <a href="/#">Notícias</a>
          <a href="/faleConosco">Fale Conosco</a>
          <a className="bold" href="https://painel.umentor.com.br/inteligente_novos/?con_cod=cia44509&pla=5">Trabalhe Conosco</a>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </header>
  );
}

export default Navbar;
