import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Cardproduto from '../cardProduto/CardProduto';

const responsive = {
    0: { items: 2 },
    598: { items: 3 },
    1024: { items: 5 },
};

function Linhas () {

    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);

    async function updateCategories () {
        const response = await fetch('https://www.canoinhas.com.br/painel/api/categories/get-all');
        const data = await response.json();

        setCategories(data);
    }

    useEffect(() => {
        setItems([]);
        var allItems = [];
        const categoriasFolhas = categories.filter((categoria) => 
            !categories.some((outraCategoria) => outraCategoria.parent === categoria.id)
        );
        console.log(categoriasFolhas);
        categoriasFolhas.forEach((category, index) => {
            allItems.push(<div className="item" data-value={index}><Cardproduto link={"/Produto?ref=" + category.id} logo={category.logo} cor={category.color} /></div>);
        });
        setItems(allItems);
    }, [categories]);

    useEffect(() => {
        updateCategories();
    }, []);

    return (<AliceCarousel
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={2500}
        animationDuration={1000}
        animationType="fadeout"
        infinite
        items={items}
        responsive={responsive}
    />)
};

export default Linhas;

