import { useState } from "react";
import "./BtnTrocaImg.css";

function BtnTrocaImg(props) {
  const [style, setStyle] = useState("cont");

  const changeStyle = () => {
    setStyle(`${props.image}`);
    props.onClick(); // Chama a função de atualização de imagem do componente pai
  };

  return (
    <>
      <a >
        <button
          className="btnTrocaImg"
          onClick={changeStyle}
          style={{ backgroundColor: `${props.cor}` }}
        >
          {props.name}
        </button>
      </a>
    </>
  );
}

export default BtnTrocaImg;
