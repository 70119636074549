import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Cardproduto from '../cardProduto/CardProduto';

function Premio() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Carousel
      swipeable
      draggable
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoplay={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-wrapper"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      centerMode
    >
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/Fritz-Muller-2008.png" /></div>
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/Fritz-Muller-2001.png" /></div>
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/Fritz-Muller-2015.png" /></div>
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/certificado3.png" /></div>
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/certificado1.png" /></div>
      <div className="slide"><img className='openImg' style={{ width: "170px", border: "1px solid #f3f3f3", borderRadius: "1rem", padding: "0.5rem" }} src="../../../../assets/certificado2.png" /></div>

    </Carousel>
  )
}

export default Premio;