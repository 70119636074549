import "./BtnOutline.css"

function BtnOutline(props) {
  return (
    <>
      <a class="btnOutline" href={props.link}>
        <i class="icon">
        </i>{props.text}</a>
    </>
  )
}


export default BtnOutline;


