import "./App.css"
import Banner from "./components/banner/Banner";
import BtnDownload from "./components/Buttons/Buttons";
import Cardproduto from "./components/cardProduto/CardProduto";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Menu/Menu";
import NoticeSection from "./components/NoticeSection/NoticeSection";
import RepresentanteSection from "./components/RepresentanteSection/RepresentanteSection";
import SectionEco from "./components/SectionEco/SectionEco";
import SectionHistoria from "./components/SectionHistoria/SectionHistoria";
import SocialMediaSection from "./components/SocialMediaSection/SocialMediaSection";

import React, { useState, useEffect } from 'react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Premio from "./components/Premio/Premio";
import Autoplay from "./components/autoplay/autoplay";
import Linhas from "./components/linhas/Linhas";

function App() {

  const [banners, setBanners] = useState([]);

  async function updateBanners () {
    fetch('https://www.canoinhas.com.br/painel/api/banners/get').then(function(response) {
      return response.json();
    }).then(function(response) {
      var banners = [];
      response.forEach((element, index) => {
        banners.push(
          <div style={{marginTop: "4rem"}} className="item" data-value={index + 1}>
              <img className="banner-home" src={element} alt="Banner" />
          </div>
        );
      });
      setBanners(response);
    }).catch(function(e){
      console.log(e);
    });
  }

  useEffect(() => {
    updateBanners();
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Autoplay items={banners} />

      <div className="sectionLinhas">
        <div className="titleSection">
          <h1>Nossos <strong>Produtos</strong></h1>
          <p>Conheça nossos produtos,
            suas linhas e variedades.</p>
        </div>
        <div className="Linhas">
          <Linhas />
        </div>
        <div className="btnCata">
          <a class="btnBaixar" href="../../assets/catalogo-cia-canoinhas.pdf" download="catalogo-cia-canoinhas.pdf">
            <i class="icon">
              <span class="material-symbols-outlined">
                enable
              </span>
            </i>Baixar Catálogo</a>
        </div>

      </div>


      <div>
        <SectionHistoria />
        <RepresentanteSection />
        <div className="Premios">
          <div className="titleSection">
            <h1>Premiações e  <strong>Certificados</strong></h1>
            <p style={{ marginBottom: "2rem" }}> Reconhecimento de Qualidade e Sucesso.</p>
          </div>
          <Premio />
        </div>
        <div className="titleNoticeSection">
          <h1>Notícias</h1>
          <p>Confira as últimas notícias em alta</p>
        </div>
        <NoticeSection />
        <SocialMediaSection />
        <Footer />
      </div>
    </div >
  );
}

export default App;
