import "./InfoSectionProduto.css"

function InfoSectionProduto(props) {
  return (
      <div className="infosDaSectionInfoProduto">
        <div className="caracteristicas">
          <div className="iconCaracteristica" style={{ backgroundColor: `${props.corIcon}` }}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nO2XUQ6DIAxA+eJOw/PNbdfS7CbiNd5CBgnb6pYSJHHhfam1faHUBI1RAljgBqyABy7hmdkb4MonYwuxjzIHDPHa1ypus3ZuMWRiCf02ILezlLGknSchJiK859LKNWKxWIzNgnfS1jFVEjbo4mO1Gnmo5ix+z+/3Fk9v8Zfp7sP1k77HpRx7uOjf8d/vcSuxjzmuyPiskQ6CiyYpnA5rcdaIbZSnlZewBGmTv4yO+cIDzGyrenzdI+wAAAAASUVORK5CYII=" alt="icone" />
          </div>
          <p>{props.item1}</p>
        </div>
        <div className="caracteristicas">
          <div className="iconCaracteristica" style={{ backgroundColor: `${props.corIcon}` }}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg==" alt="iconev" />
          </div>
          <p>{props.item2}</p>
        </div>
        <div className="caracteristicas">
          <div className="iconCaracteristica" style={{ backgroundColor: `${props.corIcon}` }}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbUlEQVR4nN1UQQrAMAzK/z/tLjvkENY0pqbMU0WCqFCzBfCiqn8e+ePovaPPGsmqiw5ZfiwBKkbsZvcZyarr4Gm0JfBgN0GgzxrJqsuMu8uPJUDFiN3sPiNZdR08jbYEHuwmCPRZI1l19oef4QGwIfwu/aZsdgAAAABJRU5ErkJggg==" alt="icone" />
          </div>
          <p>{props.item3}</p>
        </div>
      </div>
  )
}

export default InfoSectionProduto;