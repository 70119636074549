import Cardproduto from "../../../components/cardProduto/CardProduto";
import Footer from "../../../components/Footer/Footer";
import HeroProduto from "../../../components/HeroProduto/HeroProduto";
import InfoSectionProduto from "../../../components/InfoSectionProduto/InfoSectionProduto";
import Linhas from "../../../components/linhas/Linhas";
import Navbar from "../../../components/Menu/Menu";
import NoticeSection from "../../../components/NoticeSection/NoticeSection";
import SocialMediaSection from "../../../components/SocialMediaSection/SocialMediaSection";

function Institucional() {

  let icone = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nO2Wvy4FQRTGL0tChJ6ORq1S0QqP4L9WeAXeQCH3ITwC0bkhKKgkLCF0tPc2FH5yNt/eTG6uvbO7E5r9kknOOXO++WbO7JxsrVbhPwHMAufAF+XwBuz4is4ATcJiy0f4WslHwFDOSg0D7+LvaqDY72sB807iWB5R8ZfEvwH6NMw2LGYRD5IUqOcVFb8u/p4T21fsMIt4oaSFgsJX4s8BExpmGy6ziB9JCkwXFH4RfxI4BU5kG56ziJ9JCowWFG6JPyK7KdvQyiKmsDfccOJePvBdVrgBnDlxL9/hT6nUx7J7ljqBR1V7bTz9uMZ9P65QwrmfUyjhW6eBmN2zgYQSzt0yQwq72PQiBhR+Bba9iWWF/44oVMKFSwVEwECG3w8MBhMG1oE14B54AjYUewAe5a8Ad9aD7Z9K84WFY8ojLiIcaeexhp1sVSeNnUqkvp10uWM+yi3csYH2AnavHX63+fbdV6h1wQ8/6yyJAMcwXwAAAABJRU5ErkJggg=="
  let infos = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "300 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Tubete de 4,5cm de diâmetro"
    },
    {
      icon: icone,
      text: "100% Celulose"
    },
  ]

  let infos2 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "300 Metros"
    },
    {
      icon: icone,
      text: "Branquinho e Macio"
    },
    {
      icon: icone,
      text: "Tubete de 6,5cm de diâmetro"
    },
    {
      icon: icone,
      text: "100% Celulose"
    },
  ]

  let infos3 = [
    {
      cor: "#35A2DC",
      icon: icone,
      text: "Folha Simples de Alta Qualidade"
    },
    {
      icon: icone,
      text: "200 Metros"
    },
    {
      icon: icone,
      text: "Alta Absorção e Alta Resistência"
    },
    {
      icon: icone,
      text: "Tubete de 4,5cm de diâmetro"
    },
    {
      icon: icone,
      text: "100% Celulose"
    },
  ]

  const options1 = [
    {
      imagem: "../../../../assets/Institu/1023 - Best Roll.png",
      qtde: "8 Rolos",
      un: "300m",
      cor: "#A0248E",
      visible: "#A0248E"
    }
  ];
  const options2 = [
    {
      imagem: "../../../../assets/Institu/3028 - Big Roll.png",
      qtde: "8 Rolos",
      un: "300m",
      cor: "#FE6B00",
      visible: "#FE6B00"
    },
    {
      imagem: "../../../../assets/Institu/Rolão 300m 6,5 - 1028.png",
      qtde: "Rolão 300m",
      un: "em caixa",
      cor: "#FE6B00",
      visible: "#FE6B00"
    }
  ];
  const options3 = [
    {
      imagem: "../../../../assets/Institu/Toalha-All-Clean-200-m-1250.png",
      qtde: "6 Rolos",
      un: "200m",
      cor: "#6067AD",
      visible: "#6067AD"
    }
  ];

  return (
    <>
      <Navbar />
      <div className="SectionDiferencial">
        <div className="textFofinhoCheiro"  style={{marginTop: "6rem"}}>
          <h1>Linhas<br />
            <strong>Institucionais</strong></h1>
          <p>
          Para atender ao mercado institucional, disponibilizamos uma linha<br />
          completa de higiênicos e toalha de alto desempenho no formato rolão. <br />
          Produtos com excelência em qualidade e que atendem a uma<br /> demanda crescente 
          e cada vez mais exigente.
          </p>
        </div>
        <div className="imgFofinhoSectionCheiro">
          <img  style={{marginTop: "6rem"}} src="../../../assets/Institu/insHeader.png" alt="imagem fofinhos linha completa" />
        </div>
      </div>
        {/* Sexta instância do componente HeroProduto */}
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#B048A1"}
        logo={"../../../assets/Institu/bestroll.png"}
        btnCor={"#A0248E"}
        options={options1} 
        caracteristicas={infos}
      />
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#C10E2F"}
        logo={"../../../assets/Institu/bigroll2.png"}
        btnCor={"#A0248E"}
        options={options2} 
        caracteristicas={infos2}
      />
      <HeroProduto
        link={"../../../../assets/Fofinho/FS 30m 4 Rolos - 1013.png"}
        cor={"#272D72"}
        logo={"../../../assets/Institu/allclean2.png"}
        btnCor={"#A0248E"}
        options={options3} 
        caracteristicas={infos3}
      />
      
      <div className="titleLine">
        <h1>Conheça <strong>outras linhas</strong></h1>
      </div>
      <Linhas />
      <div className="titleNoticeSection">
        <h1>Notícias</h1>
        <p>Confira as últimas notícias em alta</p>
      </div>
      
      <NoticeSection />
      <SocialMediaSection />
      <Footer />
  

    </>
  )
}

export default Institucional;