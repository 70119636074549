import ButtonDefault from "../ButtonDefault/ButtonDefault";
import "./Footer.css"

function Footer() {
  return (
    <>
      <div className="footerContainer">
        <div className="footerContent">
          <div className="linksFooter">
            <div className="menuFooter">
              <div className="LogoFooter">
                <img src="../../../assets/logo.jpeg" alt="logo Cia Canoinhas" />
              </div>
              <div className="separation">
                <div className="menuFooter">
                  <a href="/sobre">Sobre nós</a>
                  <a href="/fofinho">Produtos</a>
                  <a href="notice">Notícias</a>
                  <a href="/historia">Nossa história</a>
                  <a href="/./../../assets/ISO-FSC.pdf">Política, ISO e FSC</a>
                  <a href="/./../../assets/relatorio.pdf">Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024</a>
                </ div>
                <div className="atalhosFooter">
                  <a href="#">Política e Privacidade</a>
                  <a href="https://painel.umentor.com.br/inteligente_novos/?con_cod=cia44509&pla=5">Trabalhe Conosco</a>
                  <a href="/./../../assets/catalogo-cia-canoinhas.pdf">Baixar Catálogo</a>
                  <a href="faleConosco">SAC</a>
                </div>
              </div>

            </div>

          </div>
          <div className="SocialFooter">
            <div>
              <h2>Siga nossas<br />
                <strong>Redes Sociais</strong></h2>
              <div className="iconsSocial">
                <img src="https://img.icons8.com/color/48/null/facebook-new.png" alt="facebook" />
                <img src="https://img.icons8.com/color/48/null/instagram-new--v1.png" alt="instagram" />
                <img src="https://img.icons8.com/fluency/48/null/linkedin.png" alt="linkedIn" />
              </div>
            </div>
          </div>
          <div className="wrdigital">
            <p>Desenvolvido por:</p>
            <img src="../../../assets/logowr.png" alt="logo desenvolvedor" />
          </div>
        </div>

      </div>
      <div class="footer2">
        <div class="unidade">
          <h4>FÁBRICA CANOINHAS - SC</h4>
          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(47) 3621-7000</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                location_on
              </span>
            </i>
            <p>BR-280, Parque Industrial 2,
              Canoinhas - SC, 89466-900</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                mail
              </span>
            </i>
            <p>canoinhas@canoinhas.com.br</p>
          </div>
        </div>

        <div class="unidade">
          <h4>ESCRITÓRIO DE VENDAS
            SÃO PAULO - SP</h4>
          <p>R. Sampaio Viana, 277 – 8º andar. Paraíso.  <br />São Paulo – SP  CEP: 04.004-000</p>

          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(11) 5642-3700</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                mail
              </span>
            </i>
            <p>vendas@canoinhas.com.br</p>
          </div>
        </div>

        <div class="unidade">
          <h4>ESCRITÓRIO DE VENDAS
            CURITIBA/PR</h4>
          <p>R. Capitão Souza Franco, 848 – 15º andar<br /> – cj 151 / 152.Batel. Curitiba – PR | 80.730-420</p>
          <div class="telefone">
            <i><span class="material-symbols-outlined">
              call
            </span></i>
            <p>(41) 3402-1733</p>
          </div>
          <div class="adress">
            <i>
              <span class="material-symbols-outlined">
                mail
              </span>
            </i>
            <p>comercial.cwb@canoinhas.com.br</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer;